<template>

  <div tabindex="0" :id="`overview-${parentReference}-${contentID}`"
       class="content-item"
       :class="{ noDropAllowed: showNoDropAllowed, active: isSelected, priority: isPriority, 'body-loaded': bodyLoaded, 'poster-loaded': posterLoaded, 'is-clicked': isClicked, 'liked': liked }"
       @click="_onClick" :draggable="isDraggable"
       @dragstart="_onDragStart" @dragend="_onDragEnd">

    <div class="content-item__background">
      <AAImage v-if="content && content.loadingStatus === loadingStatus.BODY_LOADED"
               class="content-item__background__image" :imageUrl="posterUrl"
               v-on:image-loaded="posterLoad"/>
      <div class="content-item__background__overlay"></div>
    </div>

    <div class="content-item__content">
      <div class="content-item__content__badges d-flex justify-content-between align-items-start">
        <div class="d-flex align-items-start">
          <span class="badge aa-content-item-type" :class="contentItemTypeClass">{{ contentItemTypeLabel }}</span>
          <img  v-if="importedFrom.length > 0" class="dms__image" :src="'img/dms/' + importedFrom + '.png'"/>
        </div>

        <span class="badge badge-success" v-if="isNew">{{ $t('ContentItemDeltaNew') }}</span>
        <span class="badge badge-info" v-if="isUpdated">{{ $t('ContentItemDeltaUpdated') }}</span>
        <span class="badge badge-danger" v-if="isDeleted">{{ $t('ContentItemDeltaDeleted') }}</span>
      </div>

      <p class="content-item__content__name" v-line-clamp="2">{{ contentName }}</p>
      <a href="#" class="content-item__content__heart" @click.stop="changeLiked">
        <i class="fa-regular fa-heart fa-lg"></i>
        <i class="fa-solid fa-heart fa-lg"></i>
      </a>
    </div>

    <div class="content-item__popover-position"></div>

    <b-popover :target="`overview-${parentReference}-${contentID}`" triggers="click blur" placement="right"
               boundary="window">
      <EntityActionBox :entity="content" :editAllowed="true"/>
    </b-popover>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";
import ContentModel from "@/content/_model/ContentModel";
import {DeltaStatus, EntityType, LoadingStatus} from "@/entity/_model/entity.constants";
import fileManager from "@/_controller/FileManager";
import AudienceModel from "@/audience/_model/AudienceModel";
import AudienceListModel from "@/audience/_model/AudienceListModel";
import languageManager from "@/__libs/language_manager/LanguageManager";
import EntityActionBox from "@/entity/_view/EntityActionBox.vue";
import EntitySelectionState from "@/entity/_model/EntitySelectionState";
import AAImage from "@/_view/components/AAImage.vue";
import {FILE_TYPE_CONFIG} from "@/asset_folder/_model/asset_folder.constants";
import ContentFileModel from "@/content_file/_model/ContentFileModel";
import contentFolderController from "@/content/_controller/ContentFolderController";
import ContentFolderListModel from "@/content/_model/ContentFolderListModel";
import ContentOverviewSelectionState from "@/content/_model/ContentOverviewSelectionState";
import {RightAction} from "@/team/_model/role.constants";
import contentListController from "@/content/_controller/ContentListController";

@Component({
    components: {
        AAImage,
        EntityActionBox
    }
})
export default class ContentOverviewItemRenderer extends Vue {

    @Prop() public content!: ContentModel;
    @Prop() private selState!: ContentOverviewSelectionState;
    @Prop() private inFunnel!: Boolean;
    @Prop() private parentReference!: string;

    private loadingStatus: typeof LoadingStatus = LoadingStatus;
    public posterLoaded: Boolean = false;
    public isClicked: boolean = false;
    public liked: boolean = false;

    get showNoDropAllowed() {
        return ContentFolderListModel.getInstance().dragInProgress;
    }

    get contentName() {
        return languageManager.getTranslationForValue(
          this.content.name,
          this.audienceLangCode
        );
    }

    get contentID() {
        return this.content.ID;
    }

    get contentItemTypeClass() {
        if (this.content.entityType === EntityType.CONTENT_FILE) {
            return `aa-file-type--${FILE_TYPE_CONFIG[(this.content as ContentFileModel).fileType].identifier.toLowerCase()}`;
        } else {
            return `aa-content-item-type--${this.content.entityType.toLowerCase()}`;

        }
    }

    get contentItemTypeLabel(): string {
        if (this.content.entityType === EntityType.CONTENT_FILE) {
            return this.$t('FileType_' + FILE_TYPE_CONFIG[(this.content as ContentFileModel).fileType].identifier) as string;
        } else {
            return this.$t(`ContentItemTypes_${this.content.entityType}`) as string;
        }
    }

    get importedFrom() {
        if ((this.content as ContentFileModel).importedFrom) {
            return (this.content as ContentFileModel).importedFrom;
        }
        return "";
    }

    get bodyLoaded() {
        return this.content.loadingStatus >= LoadingStatus.BODY_LOADED;
    }

    get isSelected() {
        return this.selState.selected === this.content;
    }

    get isNew() {
        return this.content.deltaStatus == DeltaStatus.NEW;
    }

    get isUpdated() {
        return this.content.deltaStatus == DeltaStatus.UPDATED;
    }

    get isDeleted() {
        return this.content.deltaStatus == DeltaStatus.DELETED;
    }

    get isPriority() {
        return this.content.priority > 0;
    }

    get isDraggable() {
        // const isEditable: boolean = this.content!.rightActions.indexOf(RightAction.EDIT) >= 0;
        return !this.inFunnel;
    }

    get posterUrl() {
        const posterUri: string = languageManager.getTranslationForValue(
          this.content.poster,
          this.audienceLangCode
        );
        return fileManager.getFileUrl(posterUri);
    }

    get audienceLangCode(): string | null {
        const activeAudience: AudienceModel | null = AudienceListModel.getInstance()
          .globalSelState.selected;
        return activeAudience ? activeAudience.langCode : null;
    }

    public posterLoad(e: Event) {
        this.posterLoaded = true;
    }

    private changeLiked(event: Event): void {
        event.preventDefault();
        this.liked = !this.liked;
        if (this.liked) {
            contentListController.likeContent(this.content);
        } else {
            contentListController.unlikeContent(this.content);
        }
        this.$emit("onLikeChanged", this.content);
    }

    public _onClick(p_e: Event) {
        if (this.bodyLoaded) {
            this.selState.id = `overview-${this.parentReference}-${this.contentID}`;
            this.$emit("onClick", this.content);
        }
    }

    private _onDragStart() {
        contentFolderController.startDrag(this.content);
    }

    private _onDragEnd() {
        contentFolderController.endDrag();
    }

    public mounted() {
        this.liked = contentListController.contentIsLiked(this.content);
    }

}
</script>
